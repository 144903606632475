import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Lander.module.css';
import { fetchAPIInternal } from '../utils/api';
import AuthButtons from '../components/AuthButtons';

// Define the structure of your API response if you know it
interface StartServerResponse {
  guid?: string; // Replace with actual properties of your response
  // ... other properties
}

export const Lander: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const startServer = () => {
    setIsLoading(true); // Set loading to true when the request starts
    fetchAPIInternal('/start-server', { method: 'GET' })
      .then((data: StartServerResponse) => {
        console.log('Server started:', data);
        setIsLoading(false); // Set loading to false when the request is complete
        if (data.guid) {
          // Retrieve the current array of GUIDs from localStorage, or initialize a new array if none exists
          const guids = JSON.parse(localStorage.getItem('valheimhub-anonymous-servers-started') || '[]');
          // Add the new GUID to the array
          guids.push(data.guid);
          // Save the updated array back to localStorage
          localStorage.setItem('valheimhub-anonymous-servers-started', JSON.stringify(guids));
          // Navigate to the new server page
          navigate(`/server/${data.guid}`);
        }
      })
      .catch((error: Error) => {
        console.error('Error starting server:', error);
        setIsLoading(false); // Set loading to false if there is an error
      });
  };

  return (
    <div className={styles.main}>
      <AuthButtons showSignup={true} />
      <img className={styles.logo} src={"/valheimhub-logo-transparent-small.png"} alt="Valheim Hub Logo" />
      <p className={styles.caption}>
        Welcome to valheimhub.com!
      </p>
      <p className={styles.captionSmall}>
        Go ahead and start your <span style={{ color: '#8d8', fontWeight: 'bold' }}>free</span> Valheim server.
      </p>
      <button className={styles.startServer} onClick={startServer} disabled={isLoading}>
        {isLoading ?
          (<div className={styles.spinner}></div>) :
          (<i className="bi bi-power" style={{ marginRight: '10px' }}></i>)
        }
        Start server
      </button>
      <p className={styles.startServerInfo}>No signup or credit card required!</p>
      <span style={{ marginTop: '20px' }}>
        <a href="https://discord.gg/FFwxJ229js" target="_blank" className="discord-button" rel="noreferrer">
          <img src="/discord-icon.png" height={15} />
          <span className="feedback-label">Discord</span>
        </a>
      </span>
    </div>
  );
};
