import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Lander } from './pages/Lander';
import { Dashboard } from './pages/Dashboard';
import { Server } from './pages/Server';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import PrivateRoute from './components/PrivateRoute';


function App() {
  const { user } = useAuthenticator(context => [context.user]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Lander />} />
        <Route path="/server/:guid" element={<Server />}/>
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
