import React from 'react';
import { Authenticator, Button, useAuthenticator } from '@aws-amplify/ui-react';
import styles from './AuthButtons.module.css';

const AuthButtons = ({showSignup} : {showSignup: boolean}) => {
  const { authStatus, user, signOut } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
    context.signOut,
  ]);

  function signup() {
    // Redirect to the sign up page
    window.location.href = '/dashboard?signup=1';
  }

  function login() {
    // Redirect to the login page
    window.location.href = '/dashboard';
  }

  if (authStatus === 'authenticated' && user) {
    // User is authenticated and user object is available
    return (
      <div className={styles.authButtons}>
        <span style={{marginRight: '20px', color: '#555'}}>UserID: {user.userId}</span>
        <button className={`${styles.authButton} ${styles.logoutButton}`} onClick={signOut}>Logout</button>
      </div>
    );
  } else {
    return <div className={styles.authButtons}>
      {showSignup && <button className={`${styles.authButton} ${styles.signupButton}`} style={{marginRight: '15px'}} onClick={signup}>Sign Up</button>}
      <button className={`${styles.authButton} ${styles.loginButton}`} onClick={login}>Sign In</button>
    </div>; // This is a React fragment
  }
};

export default AuthButtons;
