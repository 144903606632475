import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Authenticator, Theme, ThemeProvider, View, defaultDarkModeOverride, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import styles from './PrivateRoute.module.css';

function useQuery() {
  // This hook makes use of the useLocation hook from react-router-dom to parse query parameters
  return new URLSearchParams(useLocation().search);
}

const valheimhubTheme = (tokens: any) => {
  return {
    name: 'ValheimHub Theme',
    overrides: [defaultDarkModeOverride], // Ensure this override is defined elsewhere in your code
    tokens: {
      components: {
        authenticator: {
          router: {
            backgroundColor: '#181818'
          },
        },
        button: {
          primary: {
            backgroundColor: '#66e0b7',
          },
        },
      },
    },
  };
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
      <img className={styles.logo} src={"/valheimhub-logo-transparent-small.png"} alt="Valheim Hub Logo" />
      </View>
    );
  },
}
function PrivateRoute({ children }: { children: any }) {
  const query = useQuery();
  const { tokens } = useTheme();
  const [initialState, setInitialState] = useState<"signIn" | "signUp">("signIn");
  const theme: Theme = valheimhubTheme(tokens);

  useEffect(() => {
    // Check if the signup query parameter is present and equals "1"
    if (query.get("signup") === "1") {
      setInitialState("signUp");
    }
  }, [query]);

  return (
    <ThemeProvider theme={theme} colorMode={'dark'}>
      <Authenticator
        initialState={initialState}
        hideSignUp={initialState === 'signIn'}
        components={components}
        loginMechanisms={['email']}
      >
        {({ signOut, user }) => (
          user ? children : null // If authenticated, render children; otherwise, Authenticator handles rendering the login UI
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default PrivateRoute;
